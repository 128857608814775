<template>
  <section v-if="order !== null">
    <d-row align-h="between" align-v="center" :no-gutters="true" >

      <d-col class="table__header">
        <div class="app__subtitle">{{ $t("orders_show.title") }}</div>
        <div class="app__title" style="font-size: 1.4rem;">{{ order.customer.first_name }} {{ order.customer.last_name }} - {{ order.reference }}</div>
      </d-col>

    </d-row>

    <div class="order-details__grid  sp-mt-4">

      <!-- RESERVATION DETAIL -->
      <div class="box">
        <div class="box__heading">
          <div class="box__title">
            {{ $t("orders_show.reservation_detail") }}
          </div>
        </div>
        <div class="box__content  sp-plr-2 sp-ptb-1">
          <ul class="order-details__list">

            <li class="order-details__item">
              <div class="order-details__label">
                {{ $t("orders_show.status") }}:
              </div>
              <div class="order-details__value">
                <d-badge :class="statusLabel(order.status)">{{order.status}}</d-badge>
              </div>
            </li>

            <li class="order-details__item">
              <div class="order-details__label">
                {{ $t("orders_show.name") }}:
              </div>
              <div class="order-details__value">
                {{ order.customer.first_name }} {{ order.customer.last_name }}
              </div>
            </li>

            <li class="order-details__item">
              <div class="order-details__label">
                {{ $t("orders_show.email") }}:
              </div>
              <div class="order-details__value">
                {{ order.customer.email }}
              </div>
            </li>

            <li class="order-details__item">
              <div class="order-details__label">
                {{ $t("orders_show.phone") }}:
              </div>
              <div class="order-details__value">
                {{ order.customer.phone }}
              </div>
            </li>

            <li class="order-details__item">
              <div class="order-details__label">
                {{ $t("orders_show.price") }}:
              </div>
              <div class="order-details__value">
                {{ priceFormat(order.price.amount,order.price.currency)}}
              </div>
            </li>
            <li class="order-details__item">
              <div class="order-details__label">
                {{ $t("form_fields.country") }}:
              </div>
              <div class="order-details__value">
                 {{ order.site.split('.').pop().toUpperCase() }}
              </div>
            </li>
            <li  class="order-details__item">
              <div class="order-details__label">
              {{ $t("priceList.covered") }}: 
            </div>
              <div class="order-details__value">
                <d-form-checkbox class="mt-1 mb-1" disabled v-model="order.covered"></d-form-checkbox>
              </div>
            </li>
            <li class="order-details__item" v-if="isset(order.voucher) && !empty(order.voucher.url)">
              <div class="order-details__label">
                {{ $t("orders_show.voucher") }}:
              </div>
              <div class="order-details__value">
                <a class="order-details__voucher" :href="order.voucher.url"><i class="material-icons mr-2" style="font-size: 18px;">sim_card_download</i> {{ $t("orders_show.download_voucher") }}</a>
              </div>
            </li>

          </ul>
        </div>
      </div>

      <!-- TIMING DETAIL -->
      <div class="box">
        <div class="box__heading">
          <div class="box__title">
            {{ $t("orders_show.timing_detail") }}
          </div>
        </div>
        <div class="box__content  sp-plr-2 sp-ptb-1">

          <div class="order-details__subtitle  u-flex-base  sp-ptb-1">
            <span class="table__direction-icon  arrivals  sp-mr-1">
              <i class="material-icons">flight_takeoff</i>
            </span>
            {{ $t("orders_show.arrival") }}
          </div>
          <ul class="order-details__list">

            <li class="order-details__item">
              <div class="order-details__label">
                {{ $t("orders_show.date") }}:
              </div>
              <div class="order-details__value">
                {{ dateFormat(order.start) }}
              </div>
            </li>
            <li class="order-details__item">
              <div class="order-details__label">
                {{ $t("orders_show.time") }}:
              </div>
              <div class="order-details__value">
                {{timeFormat(order.start) }}
              </div>
            </li>

          </ul>

          <div class="order-details__subtitle  u-flex-base  sp-ptb-1  sp-mt-2">
            <span class="table__direction-icon  departures  sp-mr-1">
              <i class="material-icons">flight_land</i>
            </span>
            {{ $t("orders_show.departure") }}
          </div>
          <ul class="order-details__list">

            <li class="order-details__item">
              <div class="order-details__label">
                {{ $t("orders_show.date") }}:
              </div>
              <div class="order-details__value">
                {{ dateFormat(order.end) }}
              </div>
            </li>
            <li class="order-details__item">
              <div class="order-details__label">
                {{ $t("orders_show.time") }}:
              </div>
              <div class="order-details__value">
                {{timeFormat(order.end) }}
              </div>
            </li>

          </ul>

        </div>
      </div>

      <!-- CAR DETAIL -->
      <div class="box">
        <div class="box__heading">
          <div class="box__title">
            {{ $t("orders_show.journey_detail") }}
          </div>
        </div>
        <div class="box__content  sp-plr-2 sp-ptb-1">

          <template v-if="!empty(order.departure_flight_number) && !empty(order.departure_flight_number)">
            <div class="order-details__subtitle  sp-ptb-1">{{ $t("orders_show.departure") }}</div>
            <ul class="order-details__list sp-mb-3">

              <li class="order-details__item" v-if="!empty(order.departure_flight_number)">
                <div class="order-details__label">
                  {{ $t("orders_show.departure_flight_number") }}:
                </div>
                <div class="order-details__value">
                  {{ order.departure_flight_number }}
                </div>
              </li>

              <li class="order-details__item" v-if="!empty(order.arrival_flight_number)">
                <div class="order-details__label">
                  {{ $t("orders_show.arrival_flight_number") }}:
                </div>
                <div class="order-details__value">
                  {{ order.arrival_flight_number }}
                </div>
              </li>
            </ul>
          </template>

          <div class="order-details__subtitle  sp-ptb-1">{{ $t("orders_show.car_detail") }}</div>
          <ul class="order-details__list">

            <li class="order-details__item">
              <div class="order-details__label">
                {{ $t("orders_show.licensePlate") }}:
              </div>
              <div class="order-details__value">
                {{ order.journey.car.license_plate }}
              </div>
            </li>

            <li class="order-details__item">
              <div class="order-details__label">
                {{ $t("orders_show.carModel") }}:
              </div>
              <div class="order-details__value">
                {{ order.journey.car.model }}
              </div>
            </li>

            <li class="order-details__item">
              <div class="order-details__label">
                {{ $t("orders_show.carColor") }}:
              </div>
              <div class="order-details__value">
                {{ order.journey.car.color }}
              </div>
            </li>

            <li class="order-details__item">
              <div class="order-details__label">
                {{ $t("orders_show.persons") }}:
              </div>
              <div class="order-details__value">
                {{ order.journey.travellers }}
              </div>
            </li>

          </ul>

        </div>
      </div>

      <!-- PARKING TYPE -->
      <div v-if="parkingTypeIcon !== null">
        <div class="box">
          <div class="box__heading">
            <div class="box__title">
              {{ $t("orders_show.parking_type") }}
            </div>
          </div>
          <div class="box__content  sp-plr-2 sp-ptb-1">

            <img class="sp-mr-1" :src="require('@/assets/img/parkingTypes/' + parkingTypeIcon + '.svg')" style="max-width:320px;" />
            {{ $t("orders_show.parking_types." + parkingTypeIcon) }}

          </div>
        </div>
      </div>

    </div>

  </section>
</template>

<script>
import graphqlCalls from "~/graphql";
import mixins from "~/mixins";

export default {
  name: "OrdersShow",
  mixins: [graphqlCalls,mixins],
  props: {
    id: {
      type: String,
      required: true,
    }
  },

  data: function() {
    return {
      order: null
      
    };
  },

  computed: {

    parkingTypeIcon: function () {

      if(this.order === null) return null
     
      return this.serviceIdToCodeName(this.order.service.parking_type_id)
    },

  },

  mounted() {
  
    this.loadOrder(this.id)
  },

  methods: {

    loadOrder: function (orderId) {

      const self = this;

      this.fetchOrder(orderId).then((response) => {
        self.order = response.data.booking
        this.order.covered = self.order.service_location.services.find(service => service.id == this.order.service_id).covered
     
      })
    },
  }
};
</script>




